.custom-white-space td {
    white-space: normal;
}
.NotificationConfig{
    width: 255px;
}
.lic-link{
    color:#4556a6;
    font-weight: 800;
}
.companyTable tr .follow-button{
    border: 1px solid #738ba7;
    margin-left: 0.5rem;
    color:#738ba7;
    padding: 3px 8px;
    display: none;
    width:60px;
    height: 25px;
    font-size: 10px !important;
}
.modal-content{
    border: 1px solid #d7d6d6 !important;
}
.button-progress-sm{
    width: 15px;
    height: 15px;
    margin-left: 10px;
}
.companyTable tr>td:nth-child(1){
    width:450px;
    max-width:450px;    
    align-content: baseline;
}
.companyTable tr{
    height: 40px;
}
.companyTable tr:hover .follow-button{
    display: block !important;
}
.light-green{
    color:#31b120
}
.light-gray{
    color:#b2b2b2
}
.company-name{
    align-content: center;
    text-wrap: auto;
}

.copnay-icons{
    height: 52px;
    border-radius: 5px;
    border: 2px solid #a5a5a5;
}
.cursor-pointer {
    cursor: pointer;
}
.dropdown-menu{
    min-width:auto;
}
.modal-90w {
    max-width: 90%;
}
.image-container-logo{
    width:80px;
    text-align:right;
    align-content: center;

}
    .image-container-logo > img {
        height: 20px;
        width: 100%;
        object-fit: contain;
    }
/*Custom styles for react-data-table*/
.rdt_TableHead {
    font-size: 0.9rem !important;
    font-weight: bold !important;
    color: #838c96 !important;
}

.rdt_TableBody {
    font-size: 0.9rem !important;
    color: #838c96 !important;
}

.rdt_TableCell {
    color: #838c96 !important;
}

.XlRpR {
    display: inline !important;
}
.link-color {
    color: #304191 !important;
    font-weight:600;
}